<template>
  <div>
    <div class="e-breadcrumb">轮牌管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">名称:</span>
              <el-input placeholder="名称" size="small" style="width:265px" v-model="plateName" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">角色:</span>
              <el-select size="small" clearable v-model="role" style="width: 265px;" placeholder="请选择角色">
                <el-option
                  v-for="item in roleList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>

            </div>
            <div style="margin-right: 37px">
              <el-row type="flex" justify="end">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                </el-button>
                <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="160" class="th-l">名称:</th>
                <th width="160" class="th-l">角色</th>
                <th width="230" class="th-l">每日洗牌</th>
                <th width="230" class="th-l">收银联动</th>
                <th width="230" class="th-l">状态牌</th>
                <th width="130" class="th-l">点客是否翻牌</th>
                <th width="130" class="th-l">创建时间</th>
                <th width="200" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.plateName}}</span></td>
                <td class="td-l">
                  <span v-if="row.role==0">第一工位</span>
                  <span v-if="row.role==1">第二工位</span>
                  <span v-if="row.role==2">第三工位</span>
                </td>
                <td class="td-l">
                  <span v-if="row.isDailyShuffle==0">否</span>
                  <span v-if="row.isDailyShuffle==1">是</span>
                </td>
                <td class="td-l">
                  <span v-if="row.isCashRegisterLinkage==0">否</span>
                  <span v-if="row.isCashRegisterLinkage==1">是</span>
                </td>
                <td class="td-l">
                  <span v-if="row.isStatus==0">否</span>
                  <span v-if="row.isStatus==1">是</span>
                </td>
                <td class="td-l">
                  <span v-if="row.isFlop==0">否</span>
                  <span v-if="row.isFlop==1">是</span>
                </td>
                <td class="td-l" style="width: 150px"><span>{{row.createTime}}</span></td>
                <td class="td-l">
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                  <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
                </td>

              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delBbiRoundPlate">确 定</el-button>
            </span>
    </el-dialog>


  </div>


</template>

<script>

  export default {
    data() {
      return {
        plateName: '',
        role: '',


        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        delVisible: false,
        editItem: {},

        roleList: [
          {
            name: '第一工位',
            value: '0'
          },
          {
            name: '第二工位',
            value: '1'
          },
          {
            name: '第三工位',
            value: '2'
          },
        ],
      }
    },
    async created() {
      this.searchEvent();
    },
    methods: {

      handleCurrentChange(page) {
        this.requestSearch(page)
      },

      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {

        let currentPage = page || 1;
        let data = {
          plateName: this.plateName,
          role: this.role,
          isDel: 0,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }

        let res = await this.$get("/admin/getBbiRoundPlateForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },


      addEvent() {
        this.$router.push("/roundPlates/saveRoundPlate")
      },

      editEvent(index) {
        this.$router.push("/roundPlates/updateRoundPlate?id=" + this.list[index].id)
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除delBbiRoundPlate
      async delBbiRoundPlate() {
        let data = {
          id: this.editItem.id
        };

        let res = await this.$post("/admin/delete_bbiRoundPlate", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

    },
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
  .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
